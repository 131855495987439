@import '../../../../styles/mixins.scss';

.litresHeaderBanner {
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid rgb(136, 136, 136);
  }
  &__img {
    width: 100%;
    height: auto;
    display: block;
    @include max-screen-980 {
      display: none;
    }
    // &_tablet {
    //   display: none;
    //   @include max-screen-980 {
    //     display: block;
    //   }
    //   @include max-screen-530 {
    //     display: none;
    //   }
    // }
    &_mobile {
      display: none;
      @include max-screen-980 {
        display: block;
      }
    }
  }
}
