@import '../../../styles/mixins.scss';

.versionSticker {
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 1.4px 6px 2.4px;
  font-size: 12px;
  color: #654109;
  background: var(--btn-orange-grad, linear-gradient(180deg, #fef5d5 0%, #f2d673 73%, #ffe896 98%));
  @include max-screen-580 {
    left: 4px;
    top: 4px;
    padding: 0.4px 3px 1.4px;
    font-size: 10px;
  }
}
